import { useContext, useState } from "react";
import styles from "./SingleGameListing.module.css";
import { useNavigate } from "react-router-dom";
import PlayGameModal from "../../HomeComponents/PlayerGameModal/PlayerGameModal";
import UserContext from "../../../helpers/Context/user-context";
import { getByDisplayValue } from "@testing-library/react";

import TransactionButtons from "../../Header/HeaderComponents/TransactionButtons/TransactionButtons";
import LoginButtons from "../../Header/HeaderComponents/LoginButtons/LoginButtons";

const SingleGameList = ({ game, gameLink }) => {
  // console.log(gameLink);
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();
  const [loginPopoverOpen, setLoginPopoverOpen] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem("auth_token");
  const ctx = useContext(UserContext);
  // console.log('this games:  ', game)

  // Open Game Modal
  const openGameModal = async (game) => {
    if (!ctx.user) {
      // navigate("/login");
      setLoginPopoverOpen(true);
    } else {
      setPlayGameState(true);
      setSingleGameModal(game);
    }
  };

  return (
    <div className={styles.gamesListWrapper}>
      {token ? (
        <div className={styles.transactionButtons}>
          <TransactionButtons />
        </div>
      ) : (
        <div className={styles.loginForm}>
          <LoginButtons
            setLoginPopoverOpen={setLoginPopoverOpen}
            loginPopoverOpen={loginPopoverOpen}
          />
        </div>
      )}
      {game.icon_square ? (
        <div
          key={game.game_id}
          className={styles.gamesBodyList}
          onClick={() => openGameModal(game)}
        >
          <img className={styles.dagaTitleImage} src={game.icon_square} alt="SvenusImg" />
          {/* <button className={styles.overlayButton}>
            Choi ngay
          </button> */}
        </div>
      ) : game && (
        <div className={styles.noImage}>
          <h4>Không tìm thấy trò chơi nào</h4>
        </div>
      )}
      {playGameState && (
        <PlayGameModal
          playGameState={playGameState}
          setPlayGameState={setPlayGameState}
          singleGameModal={singleGameModal}
          setSingleGameModal={setSingleGameModal}
        />
      )}
    </div>
  );
};

export default SingleGameList;
