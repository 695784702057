import { useContext, useState } from "react";
import styles from "./SingleGameList.module.css";
import { useNavigate } from "react-router-dom";
import PlayGameModal from "../../HomeComponents/PlayerGameModal/PlayerGameModal";
import UserContext from "../../../helpers/Context/user-context";
import LazyGameImage from "./LazyGameImage";

import TransactionButtons from "../../Header/HeaderComponents/TransactionButtons/TransactionButtons";
import LoginButtons from "../../Header/HeaderComponents/LoginButtons/LoginButtons";
// import SvenusImg from '../../../assets/HomeImages/Section1/svenus-img.webp'

const SingleGameList = ({ game, gameLink, setLoginPopoverOpen1 }) => {
  // console.log(gameLink);
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();
  const [loginPopoverOpen, setLoginPopoverOpen] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem("auth_token");
  const ctx = useContext(UserContext);
  // Open Game Modal
  const openGameModal = async (game) => {
    if (!ctx.user) {
      // navigate("/login");
      setLoginPopoverOpen(true);
    } else {
      setPlayGameState(true);
      setSingleGameModal(game);
    }
  };
  return (
    <div className={styles.sectionContent}>
      {token ? (
        <div className={styles.transactionButtons}>
          <TransactionButtons />
        </div>
      ) : (
        <div className={styles.loginForm}>
          <LoginButtons
            setLoginPopoverOpen={setLoginPopoverOpen}
            loginPopoverOpen={loginPopoverOpen}
          />
        </div>
      )}
      {
        <div className={styles.imageContainer}>
          {game.icon_square ? (
            <div
              key={game.game_id}
              style={{ height: '420px' }}
            >
              <img className={styles.dagaTitleImage} src={game.icon_rectangle} alt="Hottest Image" />
              <button
                onClick={() => { openGameModal(game) }}
                className={styles.overlayButton}
              >
                Chơi ngay
              </button>
            </div>
          ) : game && (
            <div className={styles.noImage}>
              <h4>Không tìm thấy trò chơi nào</h4>
            </div>
          )}
        </div>
      }
      {
        playGameState && (
          <PlayGameModal
            playGameState={playGameState}
            setPlayGameState={setPlayGameState}
            singleGameModal={singleGameModal}
            setSingleGameModal={setSingleGameModal}
          />
        )
      }
    </div >
  );
};

export default SingleGameList;
