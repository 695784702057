import { useState, useEffect, useContext } from "react";
import style from "../../components/PopularSection/PopularSection.module.css";
import { useNavigate } from "react-router-dom";
import SingleGameList from "./SingleGameListing/SingleGameListing";
import { getGameCategories, getDagaGameCategories } from "../../helpers/APIs/GameAPIs";
import MyLoader from "../MyLoader";
import { useQuery } from "react-query";
import UserContext from "../../helpers/Context/user-context";

import { useDagaGame } from "../../helpers/useDagaGame";
import ShowGames from "../Header/HeaderComponents/SecondaryHeader/ShowGames/ShowGames";

export const PopularSection = () => {
  const [activeCatId, setActiveCatId] = useState();
  const [gameCategories, setGameCategories] = useState();
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState(null);
  const [gameLink, setGameLink] = useState("");
  const ctx = useContext(UserContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [activeCategoryId, setActiveCategoryId] = useState("");
  const [loginPopoverOpen, setLoginPopoverOpen] = useState(false);

  const { data, isLoading, error } = useQuery("categories", getGameCategories);

  const [dataLoading, setDataLoading] = useState(false);

  const [dagaGameCategories, setDagaGameCategories] = useState();
  const [showGames, setShowGames] = useState(false);
  const [dagaGames, setDagaGames] = useState(null);
  const [gameTransactionError, setGameTransactionError] = useState("");
  const [selected, setSelected] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();
  const navigate = useNavigate(UserContext);

  const [agencyModal, setAgencyModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const token = localStorage.getItem("auth_token");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const popularGameCategory = async () => {
    setLoader(true);
    try {
      const response = await getGameCategories({ 'category_name': 'Popular' });

      // fetch first category
      setGameCategories(response?.games[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setGameCategories([]);
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    popularGameCategory();
  }, []);

  return (
    <>
      {gameCategories ?
        <div className={style.wrapper}>
          <div className={style.sectionTitle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
              <path d="M319.887 3.00002L317 0.113264L314.113 3.00002L317 5.88677L319.887 3.00002ZM317 2.50002L0.5 2.49999L0.5 3.49999L317 3.50002L317 2.50002Z" fill="white" />
            </svg>

            <div className={style.title}>Phổ Biến</div>

            <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
              <path d="M0.113251 2.99999L3 5.88674L5.88675 2.99999L3 0.113236L0.113251 2.99999ZM319.5 2.50002L3 2.49999L3 3.49999L319.5 3.50002L319.5 2.50002Z" fill="white" />
            </svg>
          </div>

          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <MyLoader />
            </div>
          )}
          <div className={style.sectionContent}>
            {gameCategories && (
              gameCategories?.game_items && gameCategories?.game_items?.map((game, index) => (
                <SingleGameList
                  game={game}
                  gameLink={''}
                  activeCatId={activeCategoryId}
                />
              ))
            )}
          </div>
        </div>
        : <></>
      }
    </>
  );
};

export default PopularSection;

