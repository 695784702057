import style from "../../components/DetailsSection/DetailsSection.module.css";

export const DetailsSection = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.detailsTitle}>GA368 Nhà cái uy tín số 1 châu Á</div>
        <div className={style.detailsDescription}>
          GA368 Nhà cái uy tín số 1 châu Á GA368 là thương hiệu phát sóng trực
          tiếp đá gà hằng ngày có tỷ lệ kèo ăn cao, kết quả chính xác, cực kỳ hấp
          dẫn và gay cấn, với các trận gà đẳng cấp tiền lớn đầy kịch tính và máu
          lửa các chiến kê. Chúng tôi có phát sóng đá gà trực tiếp các thể loại
          khác nhau như : gà THOMO CPC1, CPC2, CPC3, CPC4, CPC5, gà PHI, gà THÁI
          tại GA368 luôn cam kết kết quả đá gà chính xác trung thực.
        </div>
      </div>
    </div>
  );
};

export default DetailsSection;
