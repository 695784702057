import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  AllGameWithdrawAPI,
  APIAllCategoryGames,
  getDagaGameCategories,
  getGameBalance,
  SingleGameWithdrawAPI,
  getGameCategories,
} from "../../../helpers/APIs/GameAPIs";
import { APIDagaBalance, APIDagaWithdraw } from "../../../helpers/APIs/DagaAPI";
import { APIUser } from "../../../helpers/APIs/UserAPIs";
import UserContext from "../../../helpers/Context/user-context";
import styles from "./WithdrawWalletSection.module.css";
import MyLoader from "../../../components/MyLoader";
import RefreshIcon from '../../../assets/RefreshIcon.svg';
import TransferPointsButton from "../../../components/TransferPointsButton/TransFerPointsButton";

import { SlRefresh } from "react-icons/sl";

export default function WithdrawWalletSection() {
  const ctx = useContext(UserContext);
  const queryClient = useQueryClient();
  const [allTransferLoader, setAllTransferLoader] = useState(false);
  const [gamesWithdrawError, setGamesWithdrawError] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [dagaBalance, setDagaBalance] = useState(0);
  const [gameCategories, setGameCategories] = useState([]);
  const [dagaGameCategories, setDagaGameCategories] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDaga, setLoadingDaga] = useState(false);
  const [transferingPoints, setTransferingPoints] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState({
    status: false,
    id: null,
  });
  const [balance, setBalance] = useState(false);
  const [dagaBalanceLoading, setDagaBalanceLoading] = useState({
    status: false,
    id: null,
  });

  const useAllGameswithBalance = (showBalance) => {
    return useQuery(["all games with balance"], () => APIAllCategoryGames(), {
      enabled: showBalance, // Query will be enabled if `enabled` prop is true and `id` is truthy
    });
  };

  const { data: allCategoryGames, isLoading } = useAllGameswithBalance(showBalance);

  const allWithdrawMutation = useMutation(
    (array) => {
      return AllGameWithdrawAPI(array);
    },
    {
      onSuccess: (data, variables) => {
        const allGameBalanceData = Object.entries(data[0]);
        allGameBalanceData.map((item) => {
          item.map((value) => {
            setAllTransferLoader(false);
            if (value?.message === "PENDING_WITHDRAW") {
              setGamesWithdrawError("Sắp rút lui");
            } else if (value?.message === "PENDING_TRANSACTION") {
              setGamesWithdrawError(
                "Không thể chuyển điểm vào trò chơi vì đã có một yêu cầu chuyển điểm đang chờ xử lý."
              );
            } else if (value?.message === "BALANCE_NETWORK_ERROR") {
              setGamesWithdrawError("Lỗi mạng cân bằng");
            } else if (value?.message === "LACK_FUND") {
              setGamesWithdrawError("Thiếu vốn");
            } else if (value?.message === "NETWORK_ERROR") {
              setGamesWithdrawError("Lỗi mạng");
            } else if (value?.message === "ACCOUNT_DOESNT_EXIST") {
              setGamesWithdrawError("Tài khoản không tồn tại");
            } else if (value?.message === "POINTS_TRANSFERRED_SUCCESSFULLY") {
              setBalance(!balance);
              ctx.setUserInfo({
                ...ctx.userInfo,
                balance: data[1],
              });
              const cachedData = queryClient.getQueryData(
                "all games with balance"
              );
              // Assuming cachedData is an array of games with balance
              const updatedData = cachedData.map((game) => {
                const updatedGameItems = game.game_items.map((singleGame) => {
                  const matchingGame = variables.find(
                    (item) => item.game_id === singleGame.id
                  );

                  if (matchingGame) {
                    mapBalance(0, singleGame.game_category_id, singleGame.id)
                  }

                  return {
                    ...singleGame,
                    balance: matchingGame ? 0 : singleGame.balance,
                  };
                });

                return {
                  ...game,
                  game_items: updatedGameItems,
                };
              });
              queryClient.setQueryData("all games with balance", updatedData);
              queryClient.invalidateQueries("all games with balance");
              setGamesWithdrawError();
            }
          });
        });
      },
      onSettled: async () => {
        setTimeout(() => {
          setLoading(false)
          setAllTransferLoader(false);
        }, 1000);
      },
      onError: (error) => {
        console.error("API Error:", error);
        setAllTransferLoader(false);
      },
    }
  );

  // transfer points
  const onTransferPointClicked = async (game, balance, gameItem) => {
    setTransferingPoints(true)
    try {
      const response = await SingleGameWithdrawAPI(
        game.id,
        balance
      );

      if (response) {
        handleGameBalance(
          game?.game_id,
          game?.game_platform_id,
          gameItem?.id,
          game?.id
        )
        setTransferingPoints(false)
      }
    } catch (error) {
      setTransferingPoints(false)
      console.error("Error transfering points:", error);
    }
  };

  // onTransferEverythingClicked Withdraw
  const onTransferEverythingClicked = async () => {
    try {
      const finalBalanceArray = [];
      allCategoryGames?.map((item) => {
        item?.game_items &&
          item?.game_items?.length &&
          item?.game_items?.filter((game) => {
            if (game.balance != "LỖI MẠNG" && +game?.balance > 0) {
              finalBalanceArray.push({ game_id: game.id, amount: game.balance });
            }
            // console.log(finalBalanceArray)
          });
      });
      if (finalBalanceArray?.length >= 0) {
        setLoading(true)
        setAllTransferLoader(true);
        allWithdrawMutation.mutate(finalBalanceArray);
        // transfer points to daga
        if (dagaBalance > 0) transferPointsToMain(dagaBalance)
      } else {
        setGamesWithdrawError("Không có gì để rút");
      }
    } catch (error) {
      setGamesWithdrawError("Không có gì để rút");
      console.error("Error transfering all the points:", error);
    }
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await getGameCategories();

      if (response) {
        setGameCategories(response?.games);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setGameCategories([]);
      console.error("Error fetching categories:", error);
    }
  };

  const mapBalance = (balance, item_id, id) => {
    const updatedCategories = gameCategories.map((category) => {
      if (category.id === item_id) {
        const updatedItems = category.game_items.map((game) => {
          if (game.id === id) {
            return { ...game, balance }; // Add or update the balance key
          }
          return game;
        });
        return { ...category, game_items: updatedItems };
      }
      return category;
    });
    setGameCategories(updatedCategories);
  };

  const handleGameBalance = async (game_id, game_platform_id, item_id, id) => {
    setBalanceLoading({
      status: true,
      id: id,
    });
    try {
      const response = await getGameBalance(game_id, game_platform_id);

      // console.log(response);
      setBalanceLoading({
        status: false,
        id: null,
      });

      if (response?.status) {
        mapBalance(response?.balance, item_id, id);
        if (response?.balance >= 1) {
          setBalance(true)
          setShowBalance(true)
        }
      } else {
        mapBalance(0, item_id, id);
      }
      updateMainBalance()
    } catch (error) {
      setBalanceLoading({
        status: false,
        id: null,
      });
      console.error("Error fetching categories:", error);
    }
  };

  // transfer points to daga
  const transferPointsToMain = async (amount) => {
    setLoadingDaga(true)
    try {
      const res = await APIDagaWithdraw(amount);
      if (res) {
        setDagaBalance(0)
        handleDagaBalance()
        setLoadingDaga(false)
        updateMainBalance()
      }
    } catch (error) {
      setLoadingDaga(false)
      console.error("Error transfering points:", error);
    }
  };

  const handleDagaBalance = async () => {
    setLoadingDaga(true)
    try {
      const response = await APIDagaBalance();
      setLoadingDaga(false)

      if (response) {
        setDagaBalance(response.result)
        if (response.result > 0) {
          setBalance(true)
          setShowBalance(true)
        }
        return response?.result
      }
    } catch (error) {
      setLoadingDaga(false)
      console.error("Error fetching daga balance:", error);
    }
  };

  const updateMainBalance = async () => {
    try {
      const updatedUserInfo = await APIUser();
      if (updatedUserInfo) {
        ctx.setUserInfo({
          ...updatedUserInfo,
          balance: Number(updatedUserInfo.balance),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategories();
    getDagaCategories();
    handleDagaBalance()
  }, []);

  const getDagaCategories = async () => {
    try {
      const response = await getDagaGameCategories();
      setDagaGameCategories(response?.games);
    } catch (error) {
      setDagaGameCategories([]);
      console.error("Error fetching categories:", error);
    }
  };

  return (
    <div className={styles.walletSection}>
      <div className={styles.gameSectionStyles}>
        <>
          {dagaGameCategories && (
            dagaGameCategories?.map((item, index) => (
              <>
                <div key='dagaGameCategory' className={styles.gameSection}>
                  <div
                    className={styles.games}
                    style={{
                      backgroundColor:
                        item?.game_items?.length === 0 ? `transparent` : ``,
                    }}
                  >
                    {item?.game_items &&
                      item?.game_items?.map((game, i) => (
                        <div className={styles.singleGameSection} key={i}>
                          <div className={styles.singleGameValue}>
                            <img
                              style={{ height: 30, width: '30', paddingRight: '4px' }}
                              src={game?.icon_square}
                              alt={`${game?.name ? JSON.parse(game?.name)?.en : 'Game'} Icon`}
                            />
                            <span>
                              {game?.name ? JSON.parse(game?.name)?.en : `-`}
                            </span>
                          </div>

                          {balanceLoading?.id === 'daga' &&
                            balanceLoading?.status ? (
                            <MyLoader size={16} />
                          ) :
                            <div style={{ display: 'flex' }}>
                              {loadingDaga ?
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <MyLoader size={18} />
                                </div>
                                :
                                <span
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#00C1FE'
                                  }}
                                  className={styles.otherGameBalances}
                                >
                                  {dagaBalance ? dagaBalance : 0}
                                  {dagaBalance > 0 && (
                                    <span style={{ paddingLeft: '8px', cursor: 'pointer' }} onClick={() => transferPointsToMain(parseInt(dagaBalance))}>
                                      <TransferPointsButton />
                                    </span>
                                  )}
                                  <img
                                    style={{
                                      cursor: 'pointer',
                                      paddingLeft: '8px',
                                      height: '16px'
                                    }}
                                    src={RefreshIcon}
                                    onClick={() => handleDagaBalance()}
                                    // onClick={() => {
                                    //   handleGameBalance(
                                    //     game?.game_id,
                                    //     game?.game_platform_id,
                                    //     item?.id,
                                    //     'daga'
                                    //   )
                                    // }}
                                  /></span>
                              }
                            </div>
                          }
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ))
          )}
        </>

        {gameCategories && !loading ? (
          gameCategories?.map((item, index) => (
            <div key={index} className={styles.gameSection}>
              <div
                className={styles.games}
                style={{
                  backgroundColor:
                    item?.game_items?.length === 0 ? `transparent` : ``,
                }}
              >
                {item?.game_items &&
                  item?.game_items?.map((game, i) => (
                    <div className={styles.singleGameSection} key={i}>
                      <div className={styles.singleGameValue}>
                        <img
                          style={{ height: 30, width: '30', paddingRight: '4px' }}
                          src={game?.icon_square}
                          alt={`${game?.name ? JSON.parse(game?.name)?.en : 'Game'} Icon`}
                        />
                        <span>
                          {game?.name ? JSON.parse(game?.name)?.en : `-`}
                        </span>
                      </div>

                      {balanceLoading?.id === game?.id &&
                        balanceLoading?.status ? (
                        <MyLoader size={16} />
                      ) : <>
                        <div style={{ display: 'flex' }}>
                          {game?.balance !== undefined && game?.balance ? (
                            <>
                              {game.balance > 0 ?
                                <span
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#00C1FE'
                                  }}
                                  className={styles.otherGameBalances}
                                >
                                  {game?.balance ? game?.balance : 0}
                                  <button
                                    onClick={() => {
                                      onTransferPointClicked(
                                        game,
                                        game.balance,
                                        item
                                      )
                                    }}
                                    style={{ cursor: 'pointer', background: 'none', border: 'none' }}
                                    disabled={transferingPoints}
                                  >
                                    {
                                      transferingPoints ?
                                        <MyLoader size={16} /> :
                                        <>
                                          <TransferPointsButton />
                                        </>
                                    }
                                  </button>
                                  <img
                                    style={{ cursor: 'pointer', paddingLeft: '2px', height: '16px' }}
                                    src={RefreshIcon}
                                    onClick={() => {
                                      handleGameBalance(
                                        game?.game_id,
                                        game?.game_platform_id,
                                        item?.id,
                                        game?.id
                                      )
                                    }}
                                  />
                                </span>
                                : <></>
                              }
                            </>
                          ) : (
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '16px',
                                fontWeight: '700',
                                color: '#00C1FE'
                              }}
                              className={styles.otherGameBalances}
                            >
                              {game?.balance ? game?.balance : 0}
                              <img
                                style={{ cursor: 'pointer', paddingLeft: '8px', height: '16px' }}
                                src={RefreshIcon}
                                onClick={() => {
                                  handleGameBalance(
                                    game?.game_id,
                                    game?.game_platform_id,
                                    item?.id,
                                    game?.id
                                  )
                                }}
                              /></span>
                          )}
                        </div>
                      </>}
                    </div>
                  ))}
              </div>
            </div>
          ))
        ) : loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <MyLoader />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.horizontalLine}></div>

      {gameCategories?.length > 0 && !loading ? (
        <button
          onClick={onTransferEverythingClicked}
          disabled={!balance && !dagaBalance || allTransferLoader}
          className={!balance && !dagaBalance ? styles.disabledButtonStyles : styles.buttonStyles}
        >
          {allTransferLoader ? <MyLoader size={16} /> : 'Chuyển mọi thứ vào tài khoản chính'}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
