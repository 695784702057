import { useContext, useEffect, useState } from "react";
import styles from "./RightSection.module.css";
import PointsConverter from "../../../components/PointsConverter/PointsConverter";
import PopupErrorModal from "../../../components/PopupErrorModal/PopupErrorModal";
import {
  APIMakeDepositRequest,
  depositAllowed,
} from "../../../helpers/APIs/TransactionAPI";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../helpers/Context/user-context";
import MyLoader from "../../../components/MyLoader";
import InputTooltip from "../../../components/InputTooltip/InputTooltip";
import activeBank from "../../../assets/ProfilePage/ActiveBankManagement.webp";
import CommaSeperator from "../../../components/CommaSeperator";
import { Select, MenuItem, styled } from "@mui/material";
import UrlContext from "../../../helpers/Context/url-context";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { transformDepositAmount } from "../../../helpers/NumberHelper";
import Transactions from "../../Transactions/Transactions";


const RightSection = ({
  amount,
  setAmount,
  selectedBank,
  setSelectedBank,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  selectedPaymentMethodBank,
  setSelectedPaymentMethodBank,
  companyBanks,
  errorMessage,
  setErrorMessage,
  setData,
  setActiveStep,
  bankListLoading,
  setLoading,
  loading,
  activeStep,
  setQRData,
  paymentProviders,
  selectedProviderDetail,
  setSelectedProviderDetail,
}) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodBanks, setPaymentMethodBanks] = useState([]);
  const [isDeposit, setIsDeposit] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [isDepositLoader, setIsDepositLoader] = useState(false);
  const [redirectLink, setRedirectLink] = useState("/profile/deposit");
  const [showOkBtn, setShowOkBtn] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuItem, setMenuItem] = useState("");
  const { baseUrl } = useContext(UrlContext);
  const [inputValue, setInputValue] = useState('');

  const navigate = useNavigate();
  let minAmount = 150;
  let maxAmount = 150000;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const onDepositAmountChange = (val) => {
    if (isNaN(val) || val.includes(".")) {
      setAmount(val);
      setErrorMessage("Vui lòng chỉ nhập số không có giá trị thập phân");
    } else if (!isNaN(val)) {
      setAmount(val);
      setErrorMessage("");
    }
  };
  useEffect(() => {
    setMenuItem("");
    setPaymentMethods(selectedProviderDetail ? selectedProviderDetail['payment_methods'] : [])
  }, [selectedProviderDetail]);

  useEffect(() => {
    setPaymentMethodBanks(selectedBank ? selectedBank['payment_method_banks'] : [])
  }, [selectedBank]);

  const handlePaymentMethodChange = (item) => {
    setMenuItem(item.target.value);
    const selectedPaymentMethod = paymentMethods.find(
      (selectedPM) => selectedPM.id === item.target.value
    );
    setSelectedPaymentMethod(selectedPaymentMethod)
    setSelectedBank(selectedPaymentMethod)
    setAmount('');
  };
  const handlePaymentMethodBankChange = (item) => {
    const selectedPaymentMethodBank = paymentMethodBanks.find(
      (selectedPMB) => selectedPMB.id === item.target.value
    );
    setSelectedPaymentMethodBank(selectedPaymentMethodBank)
    setAmount('');
  }
  const WhiteArrowDropDownIcon = styled(ExpandMoreOutlinedIcon)({
    color: "white",
  });
  const noBanks = () => {
    return (
      <div className={styles.loaderWrapper}>
        <MyLoader />
      </div>
    );
  };
  const companyBankSection = () => {
    return (
      <div className={styles.methodCardSection}>
        {companyBanks
          ?.sort((a, b) => a.sort_order - b.sort_order)
          ?.map((bank) => (
            <code
              key={bank.id}
              className={`${styles.methodCard} ${bank.id == selectedBank?.id ? styles.activeMethod : ""}`}
              style={{ cursor: activeStep === "step2" ? "not-allowed" : "pointer" }}
              onClick={() => setSelectedBank(bank)}
            >
              <code className={styles.imageContainer}>
                <img src={bank?.bank_image} alt="bank" />
              </code>
              <span>{bank.bank_name}</span>
            </code>
          ))}
      </div>
    );
  };
  const PaymentMethodBankSelection = () => (
    <>
      <div className={styles.bankSectionContainer}>
        <div style={{ marginTop: '21px', marginBottom: '8px', fontSize: '14px' }}>Chọn ngân hàng</div>
        <Select
          style={{ width: '100%', color: 'white', background: '#2C386E', border: '1px solid #3F519F', borderRadius: '8px', height: '45px' }}
          value={selectedPaymentMethodBank.id || menuItem}
          onChange={handlePaymentMethodBankChange}
          IconComponent={WhiteArrowDropDownIcon}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: '#2C386E',
                borderRadius: '8px',
                border: '1px solid #3F519F',
                marginTop: '4px',
                paddingTop: 0,
                '& .MuiMenuItem-root': {
                  paddingY: 2,
                },
                '& .MuiList-root': {
                  paddingY: 0
                }
              },
            },
          }}
        >
          {paymentMethodBanks?.sort((a, b) => a.sort_order - b.sort_order)?.map((bank) => (
            <MenuItem
              style={{ background: '#2C386E', color: '#fff', borderBottom: '1px solid #3F519F', margin: '0 14px' }}
              disableGutters={true}
              key={bank.id}
              value={bank?.id}
            >
              {bank.bank_name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  )

  const sugestedAmount = [
    { label: "150K", value: "150" },
    { label: "300K", value: "300" },
    { label: "900K", value: "900" },
    { label: "3 Triệu", value: "3000" },
    { label: "30 Triệu", value: "30000" },
  ];


  const sendDepositRequest = async () => {
    if (amount) {
      const payment_method = selectedPaymentMethod?.id;
      const bank_id = selectedPaymentMethodBank?.id;
      const payment_method_code = selectedPaymentMethod?.payment_method_code;
      const category_id = selectedPaymentMethod?.payment_category_id;
      const category_code = selectedProviderDetail?.category_code;
      const transformAmount = transformDepositAmount(amount);
  
      const x = await APIMakeDepositRequest(
        transformAmount,
        bank_id,
        payment_method,
        payment_method_code,
        category_id,
        category_code
      );
  
      if (x === "YOU_HAVE_PENDING_TRANSACTION") {
        setErrorModal(true);
        setErrorMessage(
          "Bạn có yêu cầu đang chờ xử lý. Bạn không thể tạo yêu cầu mới khi đang có yêu cầu chờ xử lý. Vui lòng yêu cầu lại sau."
        );
        setShowOkBtn(true);
        setRedirectLink("/profile/transactions");
      } else if (x === "FAILD_TO_GET_QR") {
        setErrorModal(true);
        setErrorMessage(
          "Ngân hàng quý khách vừa chọn hiện đang bảo trì. Vui lòng lựa chọn ngân hàng khác!"
        );
        setRedirectLink("/profile/deposit");
        setShowOkBtn(true);
      } else if (x === "WRONG_PAYMENT_METHOD") {
        setErrorModal(true);
        setErrorMessage(
          "Phương thức thanh toán bạn vừa chọn hiện đang được bảo trì. Vui lòng chọn phương thức thanh toán khác!"
        );
        setShowOkBtn(true);
        setRedirectLink("/profile/deposit");
      } else if (x === "BANK_NOT_SUPPORTED") {
        setErrorModal(true);
        setErrorMessage(
          "Ngân hàng quý khách vừa chọn hiện đang bảo trì. Vui lòng lựa chọn ngân hàng khác!"
        );
        setShowOkBtn(true);
        setRedirectLink("/profile/deposit");
      } else if (x === "NETWORK_ERROR") {
        setErrorModal(true);
        setErrorMessage(
          "Đã có lỗi trong quá trình nạp tiền. Vui lòng liên hệ Chăm sóc khách hàng"
        );
        setShowOkBtn(true);
        setRedirectLink("/profile/deposit");
      } else if (!x) {
        setErrorModal(true);
        setErrorMessage(
          "Đã có lỗi trong quá trình nạp tiền. Vui lòng liên hệ Chăm sóc khách hàng"
        );
        setShowOkBtn(true);
      } else {
        if (x.data?.is_redirect && x.data?.payment_url) {
          // Redirect to the payment URL in a new tab
          window.open(x.data.payment_url, '_blank'); 
          
          // Redirect to Transactions component after ..3 second
          setTimeout(() => {
            window.location.href = "/profile/transactions";
          }, 300); // Delay before redirecting to Transactions
        }
  
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return x;
      }
    }
    return null;
  };
  
  
  
  
  const onDepositSubmitClicked = async (e) => {
    e.preventDefault();
    setLoading(true);
    const deposit = await sendDepositRequest();
    if (deposit?.status) {
      if (deposit.data?.message === "AUTO_APPROVED") {
        navigate("/");
      } else {
        if (deposit?.is_redirect) {
          setActiveStep("redirectPage");
          setData(deposit);
        } else {
          setActiveStep("step2");
          setQRData(deposit);
        }
      }
    }
    setLoading(false);
  };

  const onContinueClicked = (e) => {
    if (
      amount &&
      !(amount < minAmount) &&
      !(amount > maxAmount) &&
      !amount.includes(".")
    ) {
      onDepositSubmitClicked(e);
    } else {
      setErrorMessage("Vui lòng nhập số tiền");
    }

  };

  return (
    <div className={styles.depositSectionOverlay}>
      <div className={styles.depositSectionWrapper}>
        <div className={styles.paymentMethodWrapper}>
          <div className={styles.paymentMethodTitle}>Chọn Phương Thức</div>
          <div className={styles.companySelection}>
            {bankListLoading ? <MyLoader /> : paymentProviders && paymentProviders?.sort((a, b) => a.sorting_order - b.sorting_order)?.map((item, index) => {
              return (
                item.id > 0 ?
                  <div
                    key={item?.payment_method_code}
                    onClick={activeStep === 'step2' || loading ? null : () => (setSelectedProviderDetail(item))}
                    className={`${styles.methodCard} ${item.id == selectedProviderDetail?.id ? styles.activeMethod : ''}`}
                    style={{ cursor: activeStep === 'step2' ? 'not-allowed' : 'pointer' }}
                  >
                    <img className="imgStyle" src={`${item.image}`} alt="bank" />
                  </div> :
                  null
              )
            }
            )}
          </div>
        </div>

        <div className={styles.horizontalLine}></div>
        <div className={styles.bankSectionContainer}>
          <div style={{ marginBottom: '8px', fontSize: '14px' }}>Các kênh thanh toán</div>
          <Select
            style={{ width: '100%', color: 'white', background: '#2C386E', border: '1px solid #3F519F', borderRadius: '8px', height: '45px' }}
            value={selectedBank?.id || menuItem}
            onChange={handlePaymentMethodChange}
            IconComponent={WhiteArrowDropDownIcon}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: '#2C386E',
                  borderRadius: '8px',
                  border: '1px solid #3F519F',
                  marginTop: '4px',
                  paddingTop: 0,
                  '& .MuiMenuItem-root': {
                    paddingY: 2,
                  },
                  '& .MuiList-root': {
                    paddingY: 0
                  }
                },
              },
            }}
          >
            {paymentMethods?.sort((a, b) => a.sort_order - b.sort_order)?.map((bank) => (
              <MenuItem
                style={{ background: '#2C386E', color: '#fff', borderBottom: '1px solid #3F519F', margin: '0 14px' }}
                disableGutters={true}
                key={bank.id}
                value={bank?.id}
              >
                {bank.payment_method_name}
              </MenuItem>
            ))}
          </Select>
        </div>

        { paymentMethodBanks.length > 0 ? <PaymentMethodBankSelection /> : null }

        <InputTooltip
          disabled={activeStep === "step2"}
          errorMessage={errorMessage}
          amount={amount}
          setAmount={setAmount}
          title={"Số điểm nạp"}
          onAmountChange={onDepositAmountChange}
        />
        <div className={styles.errorMsg}>{errorMessage}</div>
        {amount && (
          <div className={styles.depositButtonSection}>
            {sugestedAmount.map((item) => (
              <button
                onClick={() => onDepositAmountChange(item.value)}
                className={styles.depositButton}
                key={item.label}
                disabled={activeStep === "step2"}
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
        <PointsConverter
          transferPoints={transformDepositAmount(amount)}
          deposit={true}
          text="Thực tế"
        />

        {loading ? (
          <div className={styles.loader}>
            <MyLoader size={30} />
          </div>
        ) : (
          <button
            className={`${styles.submitButton} ${!amount ||
              amount < minAmount ||
              amount > maxAmount ||
              amount.includes(".")
              ? styles.disabled
              : ""
              }`}
            onClick={
              activeStep === "step2" || loading
                ? null
                : (e) => onContinueClicked(e)
            }
            disabled={isDepositLoader || !isDeposit || loading}
            style={{
              cursor:
                activeStep === "step2" || loading ? "not-allowed" : "pointer",
            }}
          >
            Tiếp theo
          </button>
        )}

        {errorModal && (
          <PopupErrorModal
            show={errorModal}
            setErrorMessage={setErrorMessage}
            setErrorModal={setErrorModal}
            message={errorMessage}
            hideModal={() => {
              setErrorModal(false);
              setErrorMessage(null);
            }}
            selectedBankId={selectedBank.id}
            redirectLink={redirectLink}
            amount={amount}
            showOkBtn={showOkBtn}
          />
          
        )}
      </div>
    </div>
  );
};


export default RightSection;
