import React, { useContext, useEffect, useState } from "react";
import styles from "./Login.module.css";
import {
  APICheckIfPhoneExists,
  APILoginUser,
} from "../../helpers/APIs/UserAPIs";
import { useNavigate } from "react-router-dom";
import UserContext from "../../helpers/Context/user-context";
import { AiOutlineArrowLeft } from "react-icons/ai";
import LoginPopupModal from "./LoginPopupModal/LoginPopupModal";
import RegistrationModal from "../../components/RegistrationModal/RegistrationModal";
import MyLoader from "../../components/MyLoader";
import OpenEye from "../../assets/LoginIcons/OpenEye.webp";
import CloseEye from "../../assets/LoginIcons/CloseEye.webp";
import Logo from "../../assets/HeaderIcons/GA6789 Logo.webp";
import { IoArrowBack, IoCall } from "react-icons/io5";
import ChickenFighting from "./../../assets/LoginIcons/ChickenFighting.webp";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { IoIosLock, IoMdClose } from "react-icons/io";

const Login = ({ handleClose, openRegisterForm }) => {
  const [registrationModal, setRegistrationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [popupError, setPopupError] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const ctx = useContext(UserContext);

  const [loginFormData, setLoginFormData] = useState({
    phone: "",
    password: "",
  });

  const handleToken = (token) => {
    setToken(token);
  };

  const handleClick = () => {
    handleClose(false);
    openRegisterForm();
  }

  const handleLoginInputChange = (e) => {
    setLoginFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e?.target?.value,
    }));
  };

  // No of requests for login
  useEffect(() => {
    if (localStorage.getItem("loginRequest")) {
      setCount(localStorage.getItem("loginRequest"));
    }
  }, []);

  //   const checkPhone = async (phone)=>{
  //     if(phone.length === 10){
  //       try{
  //         const checkPhone = await APICheckIfPhoneExists(phone);
  //         console.log(checkPhone);
  //         if(!checkPhone){
  //           setPhoneError(
  //             "Điện thoại không tồn tại. Vui lòng tạo một tài khoản mới"
  //           );
  //         }else(
  //           setPhoneError(null)
  //         )
  //       }catch(e){
  //         console.log(e);
  //       }
  //     }else{
  //       return;
  //     }

  //   }
  // useEffect(()=>{
  //   checkPhone(loginFormData.phone);
  // },[loginFormData.phone])

  // Submit function
  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();

      if (loginFormData.phone && loginFormData.password.length < 8) {
        setErrorMessage("Mật khẩu ít nhất 8 ký tự");
      } else if (
        loginFormData.phone &&
        loginFormData.phone.length === 10 &&
        loginFormData.password
      ) {
        setLoading(true);
        const x = await APILoginUser(
          loginFormData.phone,
          loginFormData.password,
          token
        );
        if (!x) {
          // error for 5 times
          setErrorMessage(
            "Tài liệu của bạn không chính xác. Vui lòng nhập lại thông tin đúng."
          );
          setLoading(false);
        } else if (x === "PASSWORD_INCORRECT") {
          setErrorMessage("Mật khẩu bạn vừa nhập không đúng.");
          setLoading(false);
        } else if (x === "PLAYER_NOT_ALLOWED_LOGIN") {
          setErrorMessage(
            "Tài khoản của bạn đang tạm khóa. Vui lòng liên hệ Chăm sóc khách hàng."
          );
          setLoading(false);
        } else if (x === "CAPTCHA_FAILED") {
          setPopupError(
            "Thông báo từ hệ thống: Đăng Nhập Thất bại !. Vui lòng liên hệ đội ngũ chăm sóc khách hàng với mã lỗi Error1003 để được hỗ trợ."
          );
          setLoading(false);
        } else if (x === "TOO_MANY_ATTEMPTS") {
          // setErrorMessage(
          //   "Thông báo từ hệ thống: Tài khoản của quý khách đã bị tạm khoá gì đăng nhập sai nhiều lần. Vui lòng liên hệ đội ngũ hỗ trợ để được giải quyết."
          // );
          setPopupError(
            "Thông báo từ hệ thống: Tài khoản của quý khách đã bị tạm khoá gì đăng nhập sai nhiều lần. Vui lòng liên hệ đội ngũ hỗ trợ để được giải quyết."
          );
          // setForgetModal(true);
          setLoading(false);

          // setErrorMessage(
          //   "Tài khoản của bạn đang tạm khóa. Vui lòng liên hệ Chăm sóc khách hàng."
          // );
          // setLoading(false);
        } else if (x.message === "REQUIRE_RESET_PASSWORD") {
          localStorage.setItem("auth_token", x.token);
          ctx.setUser(x.token);
          ctx.setUserInfo(null);
          setLoading(false);
          navigate("/", { state: { showModal: true } });
        } else {
          localStorage.setItem("auth_token", x.token);
          ctx.setUser(x.token);
          ctx.setUserInfo(null);
          setLoading(false);
          navigate("/");
        }
      }
    } catch (e) {
      // console.log(e)
    }
  };

  return (
    <div className={styles.loginWrapper}>
      {/* <div className={styles.chickenImageContainer}>
        <img
          className={styles.chickenImage}
          src={ChickenFighting}
          alt={`ChickenFighting`}
        />
      </div> */}
      <div className={styles.formOverlay}>
        <div className={styles.formWrapper}>
          <form onSubmit={onSubmitHandler}>
            <div className={styles.loginHeader}>
              Đăng Nhập
              <IoMdClose
                onClick={() => handleClose()}
                style={{ cursor: "pointer" }}
                size={26}
                color="#00C1FE"
              />
            </div>

            <div className={styles.loginFormbody}>
              <div>
                <div className={styles.requiredLabel}>
                  Số Điện Thoại<span>*</span>
                </div>
                <div className={styles.formInput}>
                  <input
                    type="number"
                    value={loginFormData.phone}
                    onChange={(e) => handleLoginInputChange(e)}
                    placeholder="Tên Đăng Nhập"
                    name="phone"
                    required
                    autoFocus
                  />

                  <div className={styles.leftIconStyles}>
                    <IoCall size={24} color="#00C1FE" />
                  </div>
                </div>
              </div>

              {loginFormData.phone && loginFormData?.phone.length !== 10 ? (
                <div className={styles.error}>Vui lòng nhập 10 ký tự</div>
              ) : (
                ""
              )}

              <div>
                <div className={styles.passwordContainer}>
                  <div className={styles.requiredLabel}>
                    Mật Khẩu <span>*</span>
                  </div>
                  {/* <span className={styles.passwordExample}>
                    Ví dụ : Daga123123 (chữ{" "}
                    <span style={{ color: "#41DF60" }}>"D"</span> viết IN HOA,
                    không dấu)
                  </span> */}
                  <div
                    className={`${styles.inputPasswordWrapper} ${styles.formInput}`}
                  >
                    <input
                      type={passwordHidden ? "password" : "text"}
                      placeholder="Xin vui lòng nhập mật khẩu"
                      name="password"
                      value={loginFormData.password}
                      onChange={(e) => {
                        handleLoginInputChange(e);
                      }}
                      required
                      autoFocus
                    />

                    <div className={styles.leftIconStyles}>
                      <IoIosLock size={24} color="#00C1FE" />
                    </div>
                    {passwordHidden ? (
                      <img
                        src={CloseEye}
                        alt="close"
                        onClick={() => setPasswordHidden(false)}
                      />
                    ) : (
                      <img
                        src={OpenEye}
                        alt="open"
                        onClick={() => setPasswordHidden(true)}
                      />
                    )}
                  </div>
                </div>
              </div>

              {errorMessage && (
                <div className={styles.error}>{errorMessage}</div>
              )}
              {phoneError && loginFormData.phone.length === 10 && (
                <div className={styles.error}>{phoneError}</div>
              )}
              <GoogleReCaptcha onVerify={handleToken} />

              {/* <div className={styles.forgotPassword}>
                <div onClick={() => {
                  navigate(`/forgot-password`);
                  handleClose();
                }}>Quên mật khẩu?</div>
              </div> */}

              {loading && (
                <div className={styles.apiResponseLoader}>
                  <MyLoader size={30} />
                  <div>
                    Đang lấy thông tin tài khoản, vui lòng chờ trong giây lát!
                  </div>
                </div>
              )}

              {loading ? (
                <MyLoader />
              ) : (
                <button
                  className={`${styles.submitButton} ${loading ? styles.loading : ""}`}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Đang tải" : "Đăng Nhập"}
                </button>
              )}

              <div className={styles.registerSection}>
                Bạn chưa có tài khoản?{" "}
                <span
                  // onClick={() => navigate("/register")}
                  onClick={handleClick}
                  className={styles.loginLink}
                >
                  Đăng ký ngay
                </span>
              </div>
            </div>
          </form>

          {/*<div style={{textAlign:'center',color:"#E2F8FF"}}>
            <p>Nếu đã có tài khoản nhấn vào đây để chơi ngay</p>
            <CoolAnimatedButton text="ĐẶT CƯỢC NGAY" link="https://sfv388.com/" />
          </div>*/}
        </div>
      </div>
      {popupError && (
        <LoginPopupModal
          message={popupError}
          show={popupError ? true : false}
          hideModal={() => {
            setPopupError("");
          }}
        />
      )}

      {registrationModal && (
        <RegistrationModal
          registrationModal={registrationModal}
          setRegistrationModal={setRegistrationModal}
        />
      )}
    </div>
  );
};

export default Login;
