import styles from "./ShowGames.module.css";
import PlayGameModal from "../../../../HomeComponents/PlayerGameModal/PlayerGameModal";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../../../helpers/Context/user-context";
import MyLoader from "../../../../MyLoader";
import useOpenPlayGameModal from "../../../../../helpers/useOpenPlayGameModal";
import { useEffect } from "react";
import C1 from "../../../../../assets/Categories/1- Casino/1.webp";
import C2 from "../../../../../assets/Categories/1- Casino/2.webp";
import C3 from "../../../../../assets/Categories/1- Casino/3.webp";
import C4 from "../../../../../assets/Categories/1- Casino/4.webp";
import C5 from "../../../../../assets/Categories/1- Casino/5.webp";
import C6 from "../../../../../assets/Categories/1- Casino/6.webp";
import C7 from "../../../../../assets/Categories/1- Casino/7.webp";
import C8 from "../../../../../assets/Categories/1- Casino/8.webp";
import C9 from "../../../../../assets/Categories/1- Casino/9.webp";

const ShowGames = ({
  showGames,
  setShowGames,
  games,
  errorMsg,
  setSelected,
  selected,
  setGames,
  setDagaGames,
  gameImgClick,
  setLoginPopoverOpen
}) => {
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();
  const [isOpened, setIsOpened] = useState(false);
  const [daga, setDaga] = useState(false);

  const navigate = useNavigate();
  const ctx = useContext(UserContext);

  const openGameModal = async (game) => {
    if (!ctx.user) {
      // navigate("/login");
      setLoginPopoverOpen(true);
    } else {
      if (window.location.pathname === '/game-links') {
        navigate("/")
      }
      if (game.game_id === 'daga' && ctx?.userInfo?.user_id === "") {
        navigate('/profile/deposit')
        setIsOpened(false)
      } else {
        setPlayGameState(true);
        setSingleGameModal(game);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpened(true);
    }, 5);

    return () => clearTimeout(timer);
  }, []);

  // no games message
  const noGamesMessage = () => {
    return (
      games?.length == 0 && (
        <span className={styles.noGamesMessage}>{errorMsg}</span>
      )
    );
  };

  // on mouse Leave Function
  const onMouseLeaveFunc = () => {
    // if (!playGameState) {
    // setSelected(false);
    setShowGames(false);
    // }
  };
  useEffect(() => {
    return () => {
      setGames([]);
      setDagaGames([]);
      setSelected(false);
    };
  }, []);
  return (
    // <div className={`${styles.showGamesWrapper}   `} >
    <div
      className={`${styles.headerGameList} ${isOpened ? styles.open : ""}`}
      onMouseEnter={() => {
        setShowGames(true);
      }}
      onMouseLeave={() => onMouseLeaveFunc()}
    >
      {
        games &&
        games.map((item) => (
          <div className={styles.gameImgWrapper} onClick={gameImgClick}>
            <div className={styles.imgWrap}>
              <img
                src={item.icon_square}
                // src={item.id ? `${item.icon_active}` : `${item.icon_image}`}
                alt={JSON.parse(item?.name).en}
                // onClick={() => {
                //   setDaga(showGames === "daga" ? true : false);
                //   openGameModal(showGames === "daga" ? "" : item);
                // }}
              />
            </div>
            <span>{JSON.parse(item?.name).en}</span>
            <button onClick={() => openGameModal(item)}>Đặt Cược</button>
          </div>
        ))
      }
      {playGameState && (
        <PlayGameModal
          playGameState={playGameState}
          setPlayGameState={setPlayGameState}
          singleGameModal={singleGameModal}
          setSingleGameModal={setSingleGameModal}
        />
      )}
    </div>
    // </div>
  );
};

export default ShowGames;
