import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import styles from "./SecondaryHeader.module.css";
import Agency from "../../../../assets/HeaderIcons/Agency.webp";
import HotTag from "../../../../assets/HeaderIcons/hot.webp";
import UserContext from "../../../../helpers/Context/user-context";
import { getGameCategories, getDagaGameCategories } from "../../../../helpers/APIs/GameAPIs";
import MyLoader from "../../../MyLoader";
import DagaSection from "../DagaSection/DagaSection";
import ShowGames from "./ShowGames/ShowGames";

import PlayGameModal from "../../../HomeComponents/PlayerGameModal/PlayerGameModal";
// import SiteLogo from "../../../../assets/NewHomeIcons/Header2Icons/Ga368 Logo.webp";
import Daga from "../../../../assets/NewHomeIcons/Header2Icons/DAGA.webp";
// import DagaIcon from "../../../../assets/NewHomeIcons/Header2Icons/DagaIcon.webp";
// import LiveCasino from "../../../../assets/NewHomeIcons/Header2Icons/Live Casino.webp";
// import Sport from "../../../../assets/NewHomeIcons/Header2Icons/Sport.webp";
// import Lottery from "../../../../assets/NewHomeIcons/Header2Icons/Lottery.webp";
// import Game from "../../../../assets/NewHomeIcons/Header2Icons/GAME.webp";
// import Fish from "../../../../assets/NewHomeIcons/Header2Icons/Fish.webp";
// import LoginButtons from "../LoginButtons/LoginButtons";
// import BalanceSection from "../BalanceSection/BalanceSection";
// import LogoutImg from "../../../../assets/NewHomeIcons/Header2Icons/Logout.webp";
// import Balance from "../../../../assets/NewHomeIcons/Header2Icons/Balance.webp";
// import LogoutConfirmationModal from "../../../LogoutConfirmationModal/LogoutConfirmationModal";
// import CommaSeperator from "../../../CommaSeperator";
import DepositIcon from "../../../../assets/NewHomeIcons/Header2Icons/Deposit (2).webp";
import WithdrawIcon from "../../../../assets/NewHomeIcons/Header2Icons/Withdrawal (1).webp";
import AffiliateIcon from "../../../../assets/NewHomeIcons/Header2Icons/affiliate 1.webp";
import InactiveIcon from "../../../../assets/NewHomeIcons/Header2Icons/Inactive - Customer Support 1.webp";
import AddIcon from "../../../../assets/NewHomeIcons/Header2Icons/add-friend 1.webp";
import InboxIcon from "../../../../assets/NewHomeIcons/Header2Icons/inbox.webp";
import LogoutIcon from "../../../../assets/NewHomeIcons/Header2Icons/Logout (1).webp";
import PersonIcon from "../../../../assets/NewHomeIcons/Header2Icons/PersonFill.webp";

//Asset
import C1 from "../../../../assets/Categories/1- Casino/1.webp";
import C2 from "../../../../assets/Categories/1- Casino/2.webp";
import C3 from "../../../../assets/Categories/1- Casino/3.webp";
import C4 from "../../../../assets/Categories/1- Casino/4.webp";
import C5 from "../../../../assets/Categories/1- Casino/5.webp";
import C6 from "../../../../assets/Categories/1- Casino/6.webp";
import C7 from "../../../../assets/Categories/1- Casino/7.webp";
import C8 from "../../../../assets/Categories/1- Casino/8.webp";
import C9 from "../../../../assets/Categories/1- Casino/9.webp";
import S1 from "../../../../assets/Categories/2- Sport/1.webp";
import S2 from "../../../../assets/Categories/2- Sport/2.webp";
import S3 from "../../../../assets/Categories/2- Sport/3.webp";
import S4 from "../../../../assets/Categories/2- Sport/4.webp";
import S5 from "../../../../assets/Categories/2- Sport/5.webp";
import S6 from "../../../../assets/Categories/2- Sport/6.webp";
import L1 from "../../../../assets/Categories/3- Lottery/1.webp";
import L2 from "../../../../assets/Categories/3- Lottery/2.webp";
import L3 from "../../../../assets/Categories/3- Lottery/3.webp";
import CG1 from "../../../../assets/Categories/4- Card Game/1.webp";
import CG2 from "../../../../assets/Categories/4- Card Game/2.webp";
import CG3 from "../../../../assets/Categories/4- Card Game/3.webp";
import CG4 from "../../../../assets/Categories/4- Card Game/4.webp";
import F1 from "../../../../assets/Categories/5- Fishing/1.webp";
import F2 from "../../../../assets/Categories/5- Fishing/2.webp";
import F3 from "../../../../assets/Categories/5- Fishing/3.webp";
import F4 from "../../../../assets/Categories/5- Fishing/4.webp";
import F5 from "../../../../assets/Categories/5- Fishing/5.webp";
import F6 from "../../../../assets/Categories/5- Fishing/6.webp";
import { APIDagaWithdraw } from "../../../../helpers/APIs/DagaAPI";
import { useDagaGame } from "../../../../helpers/useDagaGame";
import DagaErrorModal from "../../../HomeComponents/DagaErrorModal";
import LogoutConfirmationModal from "../../../LogoutConfirmationModal/LogoutConfirmationModal";
import NotificationModal from "../../../NotificationModal/NotificationModal";
import AgencyModal from "../../../AgencyModal/AgencyModal";
// import DownloadIcon from "../../../../assets/HeaderIcons/DownloadIcon.webp";
// import { AttentionSeeker } from "react-awesome-reveal";

const SecondaryHeader = ({ secondHeaderData, setLoginPopoverOpen }) => {
  const [gameCategories, setGameCategories] = useState();
  const [showGames, setShowGames] = useState(false);
  const [gameTransactionError, setGameTransactionError] = useState("");
  const [games, setGames] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [selected, setSelected] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const ctx = useContext(UserContext);
  const token = localStorage.getItem("auth_token");
  const {
    transferAllPointsInDaga,
    getDagaBalance,
    loading,
    dagaLoader,
    setDagaLoader,
    showErrorModal,
    setShowErrorModal,
  } = useDagaGame();

  // const [linkArray, setLinksArray] = useState();

  const [agencyModal, setAgencyModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();

  const [dagaGameCategories, setDagaGameCategories] = useState();
  const [dagaGames, setDagaGames] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const openGameModal = async (game) => {
    if (!ctx.user) {
      // navigate("/login");
      setLoginPopoverOpen(true);
    } else {
      setPlayGameState(true);
      setSingleGameModal(game);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLoginPopoverOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (token) {
      getDagaBalance();
      // setLinksArray([
      //   { name: "Nạp tiền", link: "/profile/deposit", image: Withdraw },
      //   { name: "Rút tiền", link: "/profile/withdraw", image: Deposit },
      //   { name: "Đại Lý", link: "/", image: PromotionIcon },
      //   {
      //     name: "Hỗ Trợ 24/7",
      //     link: "https://direct.lc.chat/13775445/",
      //     image: SupportIcon,
      //   },
      //   // { name: "Tài Khoản", link: "/profile", image: Profile },
      // ]);
    } else {
      // setLinksArray([
      //   { name: "Nạp tiền", link: "/profile/deposit", image: Withdraw },
      //   { name: "Rút tiền", link: "/profile/withdraw", image: Deposit },
      //   { name: "Khuyến mãi", link: "/promotions", image: PromotionIcon },
      //   {
      //     name: "Hỗ Trợ 24/7",
      //     link: "https://direct.lc.chat/13775445/",
      //     image: SupportIcon,
      //   },
      //   { name: "Tài Khoản", link: "/profile", image: Profile },
      // ]);
    }
    // }
  }, [token]);

  const referral = ctx.refCode && ctx.refCode;

  // use Query to get data from DB
  const { data, isLoading, error } = useQuery("categories", getGameCategories, getDagaGameCategories, {
    refetchOnWindowFocus: false,
  });
  // const { data, isLoading, error } = useQuery("categories", getGameCategories, {
  //   refetchOnWindowFocus: false,
  // });

  const gameImgClick = () => {
    window.open(
      ctx.refCode
        ? `https://${referral}.sv368vn.today/register.html`
        : `https://www.sv368.bio/register`,
      "_blank"
    );
  };

  const fallbackData = [
    {
      "games": [
        {
          "id": 4,
          "name": "{\"en\":\"Hottest\",\"vn\":\"N\óng nh\ất\"}",
          "sort_order": 4,
          "icon_image": "../../../../assets/NewHomeIcons/Header2Icons/DAGA.webp",
          "icon_active": "https://staging-gasv.iegaming.io/storage/images/game-category/LwvBnJLQ2BhUNEgX3BpInYX9e0KeXS42PhIjn63U.webp",
          "game_items": [
            {
              "id": 14,
              "game_category_id": 4,
              "game_platform_id": 3,
              "name": "{\"en\":\"Hot - Daga\",\"vn\":\"Hot - Daga\"}",
              "game_id": "daga",
              "icon_square": "https://staging-gasv.iegaming.io/storage/images/game-item/AmA00gRNpmrFnBr92c9OgedYuTfCwgFTtKWSTinQ.svg",
              "icon_rectangle": "https://staging-gasv.iegaming.io/storage/images/game-item/wZCAvAAs0D7dQe5sDLxrr2VlUaMFjXGLH8T0e0XS.webp",
              "status": 1,
              "sort_order": 8,
              "created_at": "2023-12-09T12:17:52.000000Z",
              "updated_at": "2024-12-21T10:01:09.000000Z",
              "icon": "https://staging-gasv.iegaming.io/storage/images/game-item/nnprOPulKryeeGHnCu6UcrryKPcW4tIbfa0wMd7F.webp"
            }
          ]
        }
      ],
      "rates": {
        "ty": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "zr": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "dj": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "saba": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "dy": {
          "multiply_rate": 1000,
          "min_points": 1000,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "daga": {
          "division_rate": 30,
          "min_points": 5,
          "max_points": 90000,
          "min_amount": 30,
          "max_amount": 2700000
        },
        "v8": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "mp": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "km": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "ga28": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "cq9": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "via": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "awc": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "sa": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "rich88": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "ug": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        },
        "daga2": {
          "multiply_rate": 1,
          "min_points": 1,
          "max_points": null,
          "min_amount": 1,
          "max_amount": null
        }
      }
    }
  ];

  const getDagaCategories = async () => {
    setLoader(true);
    try {
      const response = await getDagaGameCategories();

      if (response.games === null) {
        setDagaGameCategories(fallbackData[0].games); // Hardcoded response data
      } else {
        setDagaGameCategories(response?.games);
      }
    } catch (error) {
      setDagaGameCategories(fallbackData);
      // setDagaGameCategories([]);
      console.error("Error fetching categories:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getDagaCategories();
  }, []);

  // set category games data in context, manage loader
  useEffect(() => {
    // Once the data is loaded, update the state
    if (data) {
      setGameCategories(data?.games);
      ctx.setCategoryGamesContext(data);
    }
    if (isLoading) { }
    // Handle errors if any
    if (error) {
      console.error("Error fetching data:", error);
    }
  }, [data, isLoading, error]);

  // Games length checks, set error message
  useEffect(() => {
    if (gameCategories?.games?.length === 0 || dagaGameCategories?.games?.length === 0) {
      setErrorMsg("Không tìm thấy trò chơi nào");
    } else if (gameCategories === "NETWORK_ERROR" || dagaGameCategories === "NETWORK_ERROR") {
      setErrorMsg("Lỗi mạng");
    }
  }, [gameCategories, dagaGameCategories]);

  // Category hover function
  const handleHover = (id, switchProvider = 'notDaga') => {
    setSelected(id);
    if (switchProvider === 'daga') {
      setGames(dagaGameCategories[0].game_items);
    } else {
      setGames(data?.games.filter((category) => {
        return Number(category.id) === Number(id)
      })[0]?.game_items);
    }

    // setGames(
    //   gameCategoriesForTesting?.filter((category) => {
    //     return Number(category.id) === Number(id);
    //   })[0]?.game_items
    // );
  };

  // Daga Withdraw api
  const dagaWithdraw = async () => {
    if (ctx.dagaBalance < 1) {
      setGameTransactionError("Số dư tối thiểu phải là 1");
    } else {
      setDagaLoader(true);
      const dagaPoints = Math.floor(ctx.dagaBalance);
      const res = await APIDagaWithdraw(dagaPoints);
      if (res === "FORBIDDEN") {
        setGameTransactionError("CẤM");
      } else if (res === "LACK_FUND") {
        setGameTransactionError("Thiếu vốn");
      } else if (res === "NETWORK_ERROR") {
        setGameTransactionError("LỖI MẠNG");
      } else if (res === "WITHDRAW_LIMIT_OVER") {
        setGameTransactionError(
          "Tính năng rút tiền tạm đóng vì bạn đã có 1 giao dịch rút tiền hôm nay. Vui lòng thực hiện lại sau 00:00 hoặc liên hệ chăm sóc khách hàng"
        );
      } else if (res?.status) {
        ctx.setDagaBalance(ctx.dagaBalance - dagaPoints);
        ctx.setUserInfo({
          ...ctx.userInfo,
          balance: res.player_balance,
        });
      }
      setDagaLoader(false);
    }
  };

  const gameCategoriesForTesting = [
    {
      id: 1,
      name: '{"en":"CASINO","vn":"SÒNG BÀI"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C1,
        },
        {
          id: 2,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C2,
        },
        {
          id: 3,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C3,
        },
        {
          id: 4,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C4,
        },
        {
          id: 5,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C5,
        },
        {
          id: 6,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C6,
        },
        {
          id: 7,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C7,
        },
        {
          id: 8,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C8,
        },
        {
          id: 9,
          name: '{"en":"Game Name","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: C9,
        },
      ],
    },
    {
      id: 2,
      name: '{"en":"SPORTS","vn":"THỂ THAO"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S1,
        },
        {
          id: 2,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S2,
        },
        {
          id: 3,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S3,
        },
        {
          id: 4,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S4,
        },
        {
          id: 5,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S5,
        },
        {
          id: 6,
          name: '{"en":"SPORT Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: S6,
        },
      ],
    },
    {
      id: 3,
      name: '{"en":"LOTTERY","vn":"XỔ SỐ"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"Lottery Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: L1,
        },
        {
          id: 2,
          name: '{"en":"Lottery Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: L2,
        },
        {
          id: 3,
          name: '{"en":"Lottery Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: L3,
        },
      ],
    },
    {
      id: 4,
      name: '{"en":"Card","vn":"GAME BÀI"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"Card Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: CG1,
        },
        {
          id: 2,
          name: '{"en":"Card Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: CG2,
        },
        {
          id: 3,
          name: '{"en":"Card Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: CG3,
        },
        {
          id: 4,
          name: '{"en":"Card Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: CG4,
        },
      ],
    },
    {
      id: 5,
      name: '{"en":"Casino","vn":"BÁN CÁ"}',
      game_items: [
        {
          id: 1,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F1,
        },
        {
          id: 2,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F2,
        },
        {
          id: 3,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F3,
        },
        {
          id: 4,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F4,
        },
        {
          id: 5,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F5,
        },
        {
          id: 6,
          name: '{"en":"Casino Game","vn":"Tr\\u00f2 ch\\u01a1i c\\u00e2u c\\u00e1"}',
          image: F6,
        },
      ],
    },
  ];

  const DagaSection = () => (
    <>
      {dagaGameCategories?.length > 0 ? (
        dagaGameCategories?.map(
          (item) => (
            <>
              <div
                className={styles.game}
                key={item.id}
                onClick={() => openGameModal(item)}
                onMouseEnter={() => {
                  setShowGames(true);
                  handleHover(item.id, 'daga');
                }}
                onMouseLeave={() => {
                  setShowGames(false);
                }}
              >
                <img
                  style={{
                    width: "24px",
                  }}
                  src={item?.icon_image}
                  alt={"Daga"}
                />
                <span
                  className={styles.gameTitle}
                  style={{ fontSize: "14px" }}
                >
                  {JSON.parse(item?.name).vn}
                  {item.game_id}
                </span>
              </div>
              <div className={styles.divider}></div>
            </>
          )
        )
      ) : (
        <MyLoader />
      )}
    </>
  )

  return (
    <>
      <div className={styles.secondaryHeaderOverlay}>
        {/* <div className={styles.siteLogo} onClick={() => navigate("/")}>
          <img
            className={styles.siteLogoImage}
            src={SiteLogo}
            alt={"SiteLogo"}
          />
        </div> */}
        <div className={styles.gamesNavigationList}>
          <div
            className={`${styles.gameDaga} ${styles.active}`}
            onClick={() => loading || transferAllPointsInDaga()}
          >
            {/* <img className={styles.gameDagaImage} src={Daga} alt={"Daga"} /> */}
            {/* <div className={styles.gameTitleContainer}>
              <span className={styles.gameTitleDaga}>DAGA</span>
            </div> */}
          </div>
          {/* <div className={styles.divider}></div> */}
          <DagaSection />
          {gameCategories?.length > 0 ? (
            gameCategories?.map(
              (item) =>
                JSON.parse(item?.name).vn !== "Daga" && (
                  <>
                    <div
                      className={styles.game}
                      key={item.id}
                      onClick={() => openGameModal(item)}
                      onMouseEnter={() => {
                        setShowGames(true);
                        handleHover(item.id);
                      }}
                      onMouseLeave={() => {
                        // setSelected(null);
                        setShowGames(false);
                      }}
                    >
                      <img
                        style={{
                          width: "24px",
                        }}
                        src={item?.icon_image}
                        alt={"Daga"}
                      />
                      {/* <img className={styles.gameImage} src={LiveCasino} alt={"LiveCasino"} /> */}
                      <span
                        className={styles.gameTitle}
                        style={{ fontSize: "14px" }}
                      >
                        {JSON.parse(item?.name).vn}
                      </span>
                    </div>
                    <div className={styles.divider}></div>
                  </>
                )
            )
          ) : (
            // <MyLoader />
            <></>
          )}
        </div>
        {/* <div className={styles.loginButton}>
          {token ? (
            !dagaLoader ? (
              <div className={styles.transferSection}>
                <img
                  className={styles.iconSize2}
                  src={DagaIcon}
                  alt="Balance"
                />
                <span
                  className={`${
                    ctx.dagaBalance === "FORBIDDEN" ||
                    ctx.dagaBalance === "NETWORK_ERROR"
                      ? styles.balanceError
                      : styles.balance
                  }`}
                >
                  {ctx.dagaBalance === "NETWORK_ERROR"
                    ? "LỖI MẠNG"
                    : ctx.dagaBalance === "FORBIDDEN"
                    ? "CẤM"
                    : // : CommaSeperator(+ctx.dagaBalance)
                    ctx.dagaBalance > 0
                    ? CommaSeperator(ctx.dagaBalance)
                    : ctx.dagaBalance}
                </span>

                <div>
                  {ctx.dagaBalance > 0 && (
                    <button
                      className={styles.transferButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        dagaWithdraw();
                      }}
                    >
                      Chuyển ví
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <MyLoader size={16} />
            )
          ) : (
            <></>
          )}

          {Number(ctx?.userInfo?.balance) >= 0 && (
            <div className={styles.balanceSection}>
              <img className={styles.iconSize2} src={Balance} alt="Balance" />
              <span>
                {ctx.userInfo?.balance &&
                  `${CommaSeperator(Number(ctx.userInfo?.balance).toFixed(1))}`}
              </span>
            </div>
          )}

          {token ? (
            <div
              className={styles.logoutBtn}
              onClick={() => setLogoutModal(true)}
            >
              <img
                className={styles.iconSize}
                src={LogoutImg}
                alt="logoutIcon"
              />
              Đăng xuất
            </div>
          ) : (
            <LoginButtons />
          )}

          {logoutModal && (
            <LogoutConfirmationModal
              logoutModal={logoutModal}
              closeLogoutModal={() => setLogoutModal(false)}
            />
          )}
        </div> */}

        {token ? (
          <div className={styles.linkWrapper}>
            <span
              className={styles.header1_links}
              onClick={() => navigate("/profile")}
            >
              <img
                className={styles.header1_icons}
                src={PersonIcon}
              // alt={item.name}
              />
              <div>Tài khoản</div>
            </span>
            <span
              className={styles.header1_links}
              aria-describedby={id}
              onClick={handleClick}
            >
              <img
                className={styles.header1_icons}
                src={InboxIcon}
              // alt={item.name}
              />
              <div>Hộp thư</div>
            </span>
            <span
              className={styles.header1_links}
            // onClick={() => {
            //   if (item.name === "Hỗ Trợ 24/7") {
            //     window.open("https://direct.lc.chat/13775445/", "_blank");
            //   } else {
            //     navigate(item.link);
            //   }
            // }}
            >
              <img
                className={styles.header1_icons}
                src={AffiliateIcon}
              // alt={item.name}
              />
              <div>Đại Lý</div>
            </span>
            <span
              className={styles.header1_links}
            // onClick={() => {
            //   window.open("https://direct.lc.chat/13775445/", "_blank");
            // }}
            >
              <img
                className={styles.header1_icons}
                src={AddIcon}
              // alt={item.name}
              />
              <div>Invite</div>
            </span>
            <span
              className={styles.header1_links}
              onClick={() => setLogoutModal(true)}
            >
              <img
                className={styles.header1_icons}
                src={LogoutIcon}
              // alt={item.name}
              />
              <div>Đăng Xuất</div>
            </span>
          </div>
        ) : (
          <div className={styles.linkWrapper}>
            <span
              className={styles.header1_links}
              onClick={() => {
                if (token) {
                  navigate("/profile/deposit");
                } else {
                  setLoginPopoverOpen(true);
                }
              }}
            >
              <img
                className={styles.header1_icons}
                src={DepositIcon}
              // alt={item.name}
              />
              <div>Nạp Tiền</div>
            </span>
            <span
              className={styles.header1_links}
              onClick={() => {
                if (token) {
                  navigate("/profile/withdraw");
                } else {
                  setLoginPopoverOpen(true);
                }
              }}
            >
              <img
                className={styles.header1_icons}
                src={WithdrawIcon}
              // alt={item.name}
              />
              <div>Rút Tiền</div>
            </span>
            <span
              className={styles.header1_links}
              onClick={() => setAgencyModal(true)}
            >
              <img
                className={styles.header1_icons}
                src={AffiliateIcon}
              // alt={item.name}
              />
              <div>Đại Lý</div>
            </span>
            <span
              className={styles.header1_links}
              onClick={() => {
                window.open("https://direct.lc.chat/13775445/", "_blank");
              }}
            >
              <img
                className={styles.header1_icons}
                src={InactiveIcon}
              // alt={item.name}
              />
              <div>Hỗ Trợ</div>
            </span>
          </div>
        )}
      </div>

      {agencyModal && (
        <AgencyModal
          agencyModal={agencyModal}
          setAgencyModal={setAgencyModal}
        />
      )}

      {showGames && (
        <ShowGames
          showGames={showGames}
          setShowGames={setShowGames}
          games={games}
          errorMsg={errorMsg}
          setSelected={setSelected}
          selected={selected}
          setGames={setGames}
          setDagaGames={setDagaGames}
          setLoginPopoverOpen={setLoginPopoverOpen}
        />
      )}
      {showErrorModal && (
        <DagaErrorModal
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
        />
      )}

      <NotificationModal
        open={open}
        id={id}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />

      {logoutModal && (
        <LogoutConfirmationModal
          logoutModal={logoutModal}
          closeLogoutModal={() => setLogoutModal(false)}
        />
      )}
    </>
  );
};

export default SecondaryHeader;
